// css
import "../../scss/pages/home.scss";

// npm
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// utils
import foundation from "../_utils/foundation";
foundation();

// libs
import HeaderSideNav from "../_libs/HeaderSideNav";
import ScrollObserver from "../_libs/ScrollObserver";
import ScrollThrottle from "../_libs/ScrollThrottle";
import SplitTextRandom from "../_libs/SplitTextRandom";
import SplitText from "../_libs/SplitText";
import MouseStalker from "../_libs/MouseStalker";
import AnchorScroll from "../_libs/AnchorScroll";

document.addEventListener("DOMContentLoaded", () => {
	opening();

	// アンカースクロール
	AnchorScroll(".js-anchor-scroll", 0);

	new SplitTextRandom(".js-title-random-target", {
		target: ".js-title-random-text",
	});

	new SplitText(".js-title-target", {
		target: ".js-title-text",
		transition: [40],
	});
	new SplitText(".js-footer-title-target", {
		target: ".js-footer-title-text",
		transition: [70],
	});

	// header
	HeaderSideNav();
	setTimeout(function () {
		document.querySelectorAll(".js-first-animation").forEach((element) => {
			element.classList.add("is-active");
		});
	}, 350);

	const scrollObserver_block = new ScrollObserver({
		target: ".js-block-target",
		addClass: "is-active",
		once: true,
		option: {
			rootMargin: "0px 0px -30% 0px",
		},
	});
	// 監視開始;
	scrollObserver_block.init();

	// リード文
	gsap.to(".js-lead-target .char", {
		opacity: 1,
		ease: "power4.Out",
		duration: 1,
		scrollTrigger: {
			trigger: ".js-lead-target",
			start: "top center",
		},
		stagger: {
			each: 0.02,
		},
	});

	for (let index = 1; index <= 2; index++) {
		const overlay = ".js-work-overlay" + index;
		const filter = ".js-work-filter" + index;
		const trigger = ".js-work-trigger" + index;
		const background = ".js-work-background" + index;

		gsap.to(overlay, {
			backgroundColor: "rgba(0,0,0, 0.8)", // 終了時の色
			ease: "none",
			scrollTrigger: {
				trigger: trigger,
				start: "top top",
				end: "bottom top",
				scrub: 0.2,
			},
		});

		// グレースケール
		gsap.to(filter, {
			opacity: 1,
			ease: "none",
			scrollTrigger: {
				trigger: trigger,
				start: "top top",
				end: "bottom top",
				scrub: 0.2,
			},
		});

		// 背景画像のスケールアニメーション;
		gsap.to(background, {
			scale: 1,
			ease: "none",
			scrollTrigger: {
				trigger: trigger,
				start: "top top",
				end: "bottom top",
				scrub: 0.2,
			},
		});
	}

	// マウスストーカー
	if (window.matchMedia("(min-width: 768px)").matches) {
		// Workエリア
		const myObject = new MouseStalker(`.js-mouse-stalker`, `.js-work-area`);

		// 下層ページリンクエリア
		const myOtherObject = new MouseStalker(`.js-other-stalker`, `.js-other-area`);
	}

	const breakpoint = window.matchMedia("(max-width:768px)");

	breakpoint.addEventListener("change", () => {
		window.location.reload();
	});

	// Policyエリア
	const policy_target = document.querySelectorAll(".js-policy-lead-target");
	const policy_image = document.querySelector(".js-policy-lead-target");
	let policyStart = "top 60%";
	if (window.matchMedia("(min-width: 768px)").matches) {
		policyStart = "top 40%";
	}
	const tl = gsap.timeline({
		scrollTrigger: {
			trigger: ".js-policy-trigger",
			start: policyStart,
			toggleActions: "play none none none",
			markers: false,
		},
	});
	tl.add(() => {
		policy_target.forEach((element, i) => {
			setTimeout(() => {
				element.classList.add("is-active");
			}, 350 * i);
		});
	}, "+=0.2");

	const footer_target = document.querySelector(".js-footer-fix");
	const scrollObserver_target = new ScrollThrottle({
		target: ".js-fv-area",
		callback: (entry) => {
			if (entry.isIntersecting) {
				footer_target.classList?.remove("is-fixed");
			} else {
				footer_target.classList.add("is-fixed");
			}
		},
		option: {
			rootMargin: "0px 0px -60% 0px",
		},
		first: 0,
	});
	// 監視開始;
	scrollObserver_target.init();

	if (window.matchMedia("(min-width: 768px)").matches) {
		gsap.to(".js-footer-under-background", {
			scrollTrigger: {
				trigger: ".js-footer-trigger",
				start: "top bottom",
				end: "bottom bottom",
				scrub: 0.2,
				onEnter: () => {
					gsap.to(".js-footer-under-background", {
						willChange: "transform",
						transform: "translate3d(0px, 0px, 0px)",
						filter: "saturate(100%)",
					});
					gsap.to(".js-footer-item", {
						opacity: 1,
						duration: 2,
						ease: "power4.out",
						delay: 0.5,
					});
				},
				onLeaveBack: () => {
					gsap.to(".js-footer-under-background", {
						willChange: "initial",
						transform: "translate3d(0px,100rem, 0px)",
						filter: "saturate(20%)",
					});
					gsap.to(".js-footer-item", {
						opacity: 0,
						duration: 0.5,
					});
				},
			},
		});
	} else {
		gsap.to(".js-footer-under-background", {
			scrollTrigger: {
				trigger: ".js-footer-trigger",
				start: "top bottom",
				end: "bottom bottom",
				scrub: 0.2,
				onEnter: () => {
					gsap.to(".js-footer-item", {
						opacity: 1,
						duration: 2,
						ease: "power4.out",
						delay: 0.5,
					});
				},
				onLeaveBack: () => {
					gsap.to(".js-footer-item", {
						opacity: 0,
						duration: 0.5,
					});
				},
			},
		});
	}
});

window.addEventListener("load", () => {});

function opening() {
	// オープニング
	const html = document.documentElement;

	const opening2Element = document.querySelector(".js-opening2");
	const opening3Element = document.querySelector(".js-opening3");
	const viewportHeight = window.innerHeight;
	const viewportWidth = window.innerWidth;

	const calculateTargetHeight = () => {
		if (window.matchMedia("(min-width: 768px)").matches) {
			// PC
			const fontSize = parseFloat(getComputedStyle(document.documentElement).fontSize); // 1remのサイズを取得
			const remValue = 124 * fontSize; // 124remをピクセル換算

			return viewportHeight - remValue;
		} else {
			// SP
			const headerHeight = parseInt(getComputedStyle(document.documentElement).getPropertyValue("--h-header"));
			return viewportHeight - 210 * parseFloat(getComputedStyle(document.documentElement).fontSize) - headerHeight;
		}
	};

	const calculateTargetWidth = () => {
		if (window.matchMedia("(min-width: 768px)").matches) {
			const fontSize = parseFloat(getComputedStyle(document.documentElement).fontSize); // 1remのサイズを取得
			const remValue = 128 * fontSize; // 128remをピクセル換算

			return viewportWidth - remValue; // width: calc(100% - 128rem);
		} else {
			// SP
			return viewportWidth;
		}
	};

	window.addEventListener("resize", () => {
		gsap.to(".js-opening5", {
			height: calculateTargetHeight() + "px",
			duration: 0.3, // リサイズ時の短いアニメーション
		});
	});

	// 背面固定
	const backLock = (isLoack) => {
		const styles = {
			position: "fixed",
			top: "0",
			left: "0",
			width: "100%",
			height: "100%",
			overflow: "hidden scroll",
		};

		Object.keys(styles).forEach((key) => {
			document.documentElement.style[key] = isLoack ? styles[key] : "";
		});
	};

	//cookie から opening の値を取得
	const cookieValue = () => {
		if (document.cookie && document.cookie.split("; ").find((row) => row.startsWith("opening"))) {
			return document.cookie
				.split("; ")
				.find((row) => row.startsWith("opening"))
				.split("=")[1];
		} else {
			return;
		}
	};

	const param = new URLSearchParams(window.location.search);

	if (cookieValue() === "yes" && param.get("opening") !== "") {
		html.classList.add("isOpeningSkip");
		document.querySelector(".js-op").classList.add("is-hidden");
	} else {
		backLock(true);

		html.classList.add("isOpening1");
		const tl = gsap.timeline();
		tl.to(".js-op", {
			opacity: 1,
			duration: 1.2,
		})
			.add(() => {
				html.classList.add("isOpening2");
			}, "+=0.3")
			.add(() => {
				html.classList.add("isOpening3");
				opening2Element.classList.add("is-active");
			}, "+=1")
			.add(() => {
				html.classList.add("isOpening4");
				opening3Element.classList.add("is-active");
			}, "+=1.7")
			.to(
				".js-opening4",
				{
					opacity: 0,
					ease: "power4.out",
					duration: 1.8,
				},
				"+=1.3"
			)
			.to(
				".js-opening5",
				{
					width: () => calculateTargetWidth() + "px",
					height: () => calculateTargetHeight() + "px",
					ease: "power2.out",
					duration: 1,
				},
				"-=0.9"
			)
			.to(
				".js-opening6",
				{
					scale: 1,
					ease: "power2.out",
					duration: 1.8,
				},
				"<"
			)
			.to(
				".js-opening5",
				{
					opacity: 0,
					ease: "power2.out",
					duration: 1.8,
				},
				"+=0.5"
			)
			.to(
				".js-opening7",
				{
					opacity: 1,
					ease: "power2.out",
					duration: 1.8,
				},
				"<"
			)
			.to(
				".js-opening8",
				{
					opacity: 1,
					x: 0,
					ease: "power2.out",
					duration: 1,
				},
				"-=0.5"
			)
			.add(() => {
				// 画面幅が798px以下の場合のみ実行
				if (window.innerWidth <= 798) {
					return gsap.to(".js-menu-button", {
						opacity: 1,
						y: 0,
						ease: "power2.out",
						duration: 1,
					});
				} else {
					return null; // タイムラインにnullを返すとこのステップはスキップ
				}
			})
			.to(
				".js-op",
				{
					opacity: 0,
					ease: "power2.out",
					duration: 0.5,
					onComplete: () => {
						backLock(false);
					},
				},
				"<"
			)
			.add(() => {
				document.querySelector(".js-op").classList.add("is-hidden");
			});

		// cookie設定;
		document.cookie = "opening=yes; max-age=259200; path=/";
	}
}
