export default class SplitTextRandom {
	/**
	 * @class SplitTextRandom
	 * @constructor
	 * @param {String} els - 対象要素（メイン）
	 * @param {Object} Options - オプション
	 * @param {any} [Options.target] - 対象要素（複数行の場合は行ごとに指定）
	 * @param {String} [Options.className] - 付与するクラス
	 * @param {Number} [Options.baseDelay] - ベースになるディレイがある場合は指定（ms）
	 * @example
		new SplitTextRandom(".js-s-block", {
			target: ".js-s-text",
		});
		ただ文字を分割するだけのクラス
	 */

	constructor(els, { target = false, className = "char", baseDelay = 0 } = {}) {
		this.els = document.querySelectorAll(els);

		if (!this.els) {
			console.error(`SplitText: ${els} が見つかりませんでした。`);
			return;
		}

		this.className = className;
		this.baseDelay = baseDelay;

		// 対象要素が複数行の場合は、それぞれに対応する
		// テキストを分割する
		this.target = this.els;

		this.els.forEach((element) => {
			if (target) {
				this.target = element.querySelectorAll(target);

				this.target.forEach((target) => {
					const span = document.createElement("span");
					span.innerHTML = target.innerHTML;
					span.classList.add("uVisuallyHidden");
					target.insertAdjacentElement("afterend", span);

					/**
					 * @type {Array<any>}
					 */
					this.chars = target.innerHTML.trim().split("");
					target.innerHTML = this._splitText();
				});
			} else {
				const span = document.createElement("span");
				span.innerHTML = element.innerHTML;
				span.classList.add("uVisuallyHidden");
				element.insertAdjacentElement("afterend", span);

				/**
				 * @type {Array<any>}
				 */
				this.chars = element.innerHTML.trim().split("");
				element.innerHTML = this._splitText();
			}
		});
	}

	_splitText() {
		return this.chars.reduce((acc, curr) => {
			if (curr === "＆") {
				curr = curr.replace("＆", "&amp;");
			} else if (curr.match(/\s+/)) {
				curr = curr.replace(/\s+/g, "&nbsp;");
			}

			return `${acc}<span class="${this.className}" aria-hidden="true">${curr}</span>`;
		}, "");
	}
}
