// @ts-check
export default class MouseStalker {
	constructor(target, area) {
		this.stalker = document.querySelector(target);
		this.area = document.querySelector(area);

		this.posX = 0;
		this.posY = 0;

		// 初期位置を画面中央に設定
		this.posX = window.innerWidth / 2;
		this.posY = window.innerHeight / 2;

		this._updatePosition();

		// 全体のmousemoveイベントを設定
		document.addEventListener("mousemove", this._move.bind(this));

		// エリアへの進入/退出イベントを設定
		this.area.addEventListener("mouseenter", this._mouseenter.bind(this));
		this.area.addEventListener("mouseleave", this._mouseleave.bind(this));
	}
	_updatePosition() {
		this.stalker.style.setProperty("--mouse-x", `${this.posX}px`);
		this.stalker.style.setProperty("--mouse-y", `${this.posY}px`);
		this.stalker.style.transform = `translate(${this.posX}px,${this.posY}px)`;
	}
	_mouseenter() {
		this.stalker.classList.add("is-active");
	}

	_mouseleave() {
		this.stalker.classList.remove("is-active");
	}

	_move(e) {
		this.posX = e.clientX;
		this.posY = e.clientY;
		this._updatePosition();
	}
}
